import * as React from 'react';

function useZoomEffect<T extends HTMLElement, U extends HTMLElement>(
  fadeOut = false,
): [React.RefObject<T>, React.RefObject<U>] {
  const scrolledElement = React.useRef<T>(null);
  const animatedElement = React.useRef<U>(null);

  React.useEffect(() => {
    const onScrollHandler = () => {
      if (scrolledElement.current && animatedElement.current) {
        const rect = scrolledElement.current.getBoundingClientRect();
        const animationProgress = Math.max(
          0,
          ((window.innerHeight - rect.top) * 1.25) / window.innerHeight,
        );

        const opacity = Math.max(
          0,
          Math.min(
            1,
            animationProgress > 1.35 && fadeOut
              ? 1 - (animationProgress - 1.35) * 3
              : animationProgress,
          ),
        );
        const scale = 25 - 24 * Math.min(1, animationProgress);

        requestAnimationFrame(() => {
          if (animatedElement.current) {
            // eslint-disable-next-line @ecocode/no-multiple-style-changes
            animatedElement.current.style.opacity = `${opacity}`;
            animatedElement.current.style.transform = `scale(${scale})`;
          }
        });
      }
    };

    window.addEventListener('scroll', onScrollHandler, { passive: true });
    onScrollHandler();
    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, [fadeOut]);

  return [scrolledElement, animatedElement];
}

export default useZoomEffect;
