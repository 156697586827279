import React from 'react';
import { useTranslation } from 'react-i18next';
import { GatsbyImageFileWithName } from '../../utils/graphql';
import Banner from '../clients/Banner';

type ClientProps = {
  clientImages: GatsbyImageFileWithName[];
};

const Clients: React.VoidFunctionComponent<ClientProps> = ({ clientImages }) => {
  const { t } = useTranslation('landing');
  return (
    <section id="clients">
      <div className="bg-white text-black md:h-screen md:snap-start">
        <div className="flex h-full flex-col justify-center py-8 text-center sm:py-0">
          <h3 className="mb-10">{t('clients.title')}</h3>
          <p className="mb-4 text-xl font-medium sm:text-2xl">{t('clients.subtitle')}</p>
          <div className="pb-8 pt-8 xs:pt-24 sm:pt-16">
            <Banner clientImages={clientImages} imageClassName="mx-8 h-44 w-44" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
