import * as React from 'react';
import { LocalizedLink } from 'gatsby-theme-i18n';
import DiscoverDesign from './DiscoverDesign';

type DiscoverButtonProps = {
  href: string;
  title: string;
  text?: string;
  pseudoClass: string;
};

const DiscoverButton: React.VoidFunctionComponent<DiscoverButtonProps> = ({
  href,
  title,
  text,
  pseudoClass,
}) => {
  return (
    <DiscoverDesign
      component={LocalizedLink}
      title={title}
      text={text}
      pseudoClass={pseudoClass}
      props={{ to: href }}
    />
  );
};

export default DiscoverButton;
