import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { LocalizedLink } from 'gatsby-theme-i18n';
import AllExpertises from '../components/expertises/AllExpertises';
import Clients from '../components/landing/Clients';
import Footer from '../components/landing/Footer';
import Introduction from '../components/landing/Introduction';
import ScoopLanding from '../components/landing/ScoopLanding';
import Offers from '../components/landing/Offers';
import PresentationVideo from '../components/landing/PresentationVideo';
import TheyTalkAboutUs from '../components/landing/TheyTalkAboutUs';
import * as Colors from '../components/Colors';
import DefaultLayout from '../components/layouts/default-layout';
import { PageContextProps, AllImage } from '../utils/graphql';
import useZoomEffect from '../utils/useZoomEffect';

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "clients" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
        name
      }
    }
  }
`;

type HomeQueryProps = {
  allFile: AllImage;
};

type HomeProps = PageProps<HomeQueryProps, PageContextProps>;

const Homepage: React.VoidFunctionComponent<HomeProps> = ({
  data: {
    allFile: { nodes },
  },
  pageContext,
}) => {
  const { t } = useTranslation('landing');

  const pageTitle = t('head-title');

  const [scrolledElement, animatedElement] = useZoomEffect<HTMLDivElement, HTMLHeadingElement>(
    true,
  );

  return (
    <DefaultLayout
      headTitle={pageTitle}
      headDescription={t('head-description')}
      lang={pageContext.locale}
      theme={{ class: Colors.WHITE_ON_BLUE, pseudoClass: Colors.WHITE_ON_BLUE_PSEUDO_CLASS }}
      preserveNavbarSpace={false}
      showContactForm={false}
    >
      <Introduction linkTo="/#scoop-landing" />
      <div className="relative -z-20 flex h-screen w-0 snap-start"></div>
      <div className="relative z-10">
        <ScoopLanding />
        <PresentationVideo />
        <Offers />
        <AllExpertises scrollSnap>
          <div
            ref={scrolledElement}
            className="w-full overflow-hidden bg-black py-32 text-white lg:h-screen lg:snap-start"
          >
            <div className="container relative flex h-full flex-col items-center justify-center px-4 sm:px-8">
              <div className="absolute left-1/2 top-1/2 -ml-36 -mt-36 h-72 w-72 rounded-full bg-reacteev-blue opacity-70 lg:-ml-48 lg:-mt-48 lg:h-96 lg:w-96" />
              <h3
                ref={animatedElement}
                className="relative max-w-4xl origin-[50%_50%_0px] text-3xl sm:text-5xl md:text-6xl"
              >
                {t('expertises.title')}
              </h3>
              <LocalizedLink
                className="absolute top-1/2 mt-40 w-full text-center text-base font-medium sm:mt-40 sm:text-2xl md:mt-52 lg:mt-60"
                title={t('expertises.subtitle')}
                to="/#expertises"
              >
                {t('expertises.subtitle')}
              </LocalizedLink>
            </div>
          </div>
        </AllExpertises>
        <Clients clientImages={nodes} />
        <TheyTalkAboutUs />
        <Footer pageTitle={pageTitle} scrollSnap />
      </div>
    </DefaultLayout>
  );
};

export default Homepage;
